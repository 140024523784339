<div class="search-dropdown">
	<div class="dropdown-filters">
			<button
				*ngFor="let filter of visibleFilterOptions"
				pButton
				label="{{filter.name}}"
				icon="pi {{filter.icon}}"
				class="p-button-rounded p-button-text"
				[class.active]="isFilterActive(filter.index)"
				(click)="toggleFilter(filter.index)">
			</button>
	</div>

	<div *ngIf="filterOptions.length > 6" class="expand-icon" (click)="toggleFilterVisibility()">
		<tb-btn
			[class]="(filtersExpanded ? 'pi-chevron-up' : 'pi-chevron-down') + ' pi'"
			label=""
			[pTooltip]="(filtersExpanded ? 'Weniger' : 'Mehr') + ' Anzeigen'"
		>
		</tb-btn>
	</div>

	<div class="filtered-data" *ngIf="searchResults.length > 0">
		<h4>Suchergebnisse</h4>
		<ul>
			<li pRipple
					class="cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
					*ngFor="let searchResult of getPaginatedResults()"
					(click)="navigateToResult(searchResult)">
				<span class="item-name">{{ searchResult.result }}</span>
				<div class="item-information">
				<tb-items-overlay
					class="items-overlay"
					*ngIf="searchResult.items"
					[items]="searchResult.items"
				/>
				<span class="item-category">{{ getTranslatedType(searchResult.index) }}</span>
				</div>
			</li>
		</ul>
	</div>

	<div class="pagination-controls" *ngIf="getTotalPages() > 1">
		<button pButton label="" icon="pi pi-chevron-left" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1"></button>
		<span>Seite {{currentPage}} von {{getTotalPages()}}</span>
		<button pButton label="" icon="pi pi-chevron-right" (click)="changePage(currentPage + 1)" [disabled]="currentPage === getTotalPages()"></button>
	</div>
</div>