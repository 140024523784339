import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { InputTextModule } from 'primeng/inputtext'
import { ButtonModule } from 'primeng/button'
import { BadgeModule } from 'primeng/badge'
import { AvatarModule } from 'primeng/avatar'
import { PanelModule } from 'primeng/panel'
import { ButtonComponent } from '@app/components/uiux/button/button.component'
import { TooltipModule } from 'primeng/tooltip'
import { SearchResult } from '@core/models/shared/search.model'
import { Ripple } from 'primeng/ripple'
import { Router } from '@angular/router'
import { AuthService } from '@core/services/api/auth.service'
import { Permissions } from '@core/common/permissions'
import { ItemsOverlayComponent } from '@app/components/uiux/items-overlay/items-overlay.component'

type Filter = {
	name: string;
	icon: string;
	index: string;
	permission: number;
}

@Component({
	selector: 'tb-search-overlay',
	standalone: true,
	templateUrl: 'search-overlay.component.html',
	styleUrl: './search-overlay.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CommonModule,
		FormsModule,
		InputTextModule,
		ButtonModule,
		AvatarModule,
		BadgeModule,
		PanelModule,
		ButtonComponent,
		TooltipModule,
		Ripple,
		ItemsOverlayComponent
	]
})
export class SearchOverlayComponent {
	@Input() activeFilters: string[] = []
	@Input() searchResults: SearchResult[] = []
	@Output() activeFilterChanged = new EventEmitter<string>()
	@Output() onRouteChange = new EventEmitter()

	protected filterOptions: Filter[] = [
		{name: 'Aufträge', icon: 'pi-inbox', index: 'salesorder', permission: Permissions.SALES_ORDERS_GETCOLLECTION},
		{name: 'Bestellungen', icon: 'pi-cart-arrow-down', index: 'purchaseorder', permission: Permissions.PURCHASE_ORDERS_GETCOLLECTION},
		{name: 'Artikel', icon: 'pi-list', index: 'article', permission: Permissions.SHARED_ARTICLES_GETCOLLECTION},
		{name: 'Kunden', icon: 'pi-users', index: 'customer', permission: Permissions.SALES_CUSTOMERS_GETCOLLECTION},
		{name: 'Wareneingang', icon: 'pi-warehouse', index: 'goodsreceipt', permission: Permissions.LOGISTICS_GOODSRECEIPT_GETCOLLECTION},
		{name: 'Warenausgang', icon: 'pi-truck', index: 'goodsissue', permission: Permissions.LOGISTICS_GOODSISSUE_GETCOLLECTION},
		{name: 'Leads', icon: 'pi-building', index: 'lead', permission: Permissions.SALES_CRM_LEADS_GETCOLLECTION},
		{name: 'Lieferanten', icon: 'pi-sitemap', index: 'supplier', permission: Permissions.PURCHASE_SUPPLIERS_GETCOLLECTION},
		{name: 'Rechnungen', icon: 'pi-receipt', index: 'invoice', permission: Permissions.SALES_INVOICES_GETCOLLECTION},
		//{name: 'Warenrücksendung', icon: 'pi-step-backward', index: 'goodsreturn', permission: Permissions.LOGISTICS_GOODSRETURN_GETCOLLECTION},
		{name: 'R. Korrekturen', icon: 'pi-file-export', index: 'invoicecorrection', permission: Permissions.SALES_INVOICES_GETCOLLECTION}
	]

	private routeOptions = {
		salesorder: "sales/orders/",
		purchaseorder: "purchase/orders/",
		article: "goods/articles/",
		customer: "sales/customers/",
		goodsreceipt: "logistics/goods-receipt/",
		goodsissue: "logistics/goods-issue/",
		lead: "sales/crm/leads/",
		supplier: "purchase/suppliers/",
		invoice: "sales/invoices/",
		goodsreturn: "",
		invoicecorrection: "sales/invoice-corrections/",
	}

	protected filtersExpanded: boolean = false
	protected visibleFilterOptions: Filter[] = []
	protected currentPage: number = 1
	protected itemsPerPage: number = 12

	constructor(
		protected router: Router,
		protected authService: AuthService
	) {
		this.filterOptions = this.filterOptions.filter((filter: Filter) => this.authService.hasPermission(filter.permission))
		this.visibleFilterOptions = this.filterOptions.slice(0, 6)
		this.checkScreenSize()
	}

	toggleFilter(filter: string): void {
		this.activeFilterChanged.emit(filter)
	}

	toggleFilterVisibility(): void {
		this.filtersExpanded = !this.filtersExpanded
		this.visibleFilterOptions = this.filtersExpanded ? this.filterOptions : this.filterOptions.slice(0, 6)
	}

	isFilterActive(filter: string): boolean {
		return this.activeFilters.includes(filter)
	}

	navigateToResult(result: SearchResult): void {
		this.router.navigate([this.routeOptions[result.index as keyof typeof this.routeOptions] + result.id])
		this.onRouteChange.emit()
	}

	getTranslatedType(type: string): string {
		return this.filterOptions.find(f => f.index === type)?.name || type
	}

	getTotalPages(): number {
		return Math.ceil(this.searchResults.length / this.itemsPerPage)
	}

	getPaginatedResults(): SearchResult[] {
		const start = (this.currentPage - 1) * this.itemsPerPage
		const end = start + this.itemsPerPage
		return this.searchResults.slice(start, end)
	}

	changePage(page: number): void {
		if (page >= 1 && page <= this.getTotalPages()) {
			this.currentPage = page;
		}
	}

	checkScreenSize(): void {
		if(window.innerWidth > 1800) this.itemsPerPage = 12
		else this.itemsPerPage = 6
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.checkScreenSize();
	}
}
