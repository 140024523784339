import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { Observable } from 'rxjs'
import { SearchResult } from '@core/models/shared/search.model'

@Injectable({ providedIn: 'root' })
export class SearchService extends ApiService {
  protected override endpoint: string = 'shared/search'

  public search(searchData: object): Observable<SearchResult[]> {
    return this._post(searchData)
  }

}
