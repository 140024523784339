import { Component, ElementRef, HostListener, ViewEncapsulation } from '@angular/core'
import { LocalSearchService } from '@core/services/search.service'
import { InputTextModule } from 'primeng/inputtext'
import { PaginatorModule } from 'primeng/paginator'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { Subject } from 'rxjs'
import { ButtonModule } from 'primeng/button'
import { InputSwitchModule } from 'primeng/inputswitch'
import { TooltipModule } from 'primeng/tooltip'
import { SearchOverlayComponent } from '@app/components/uiux/searchbar/search-overlay/search-overlay.component'
import { NgIf } from '@angular/common'
import { SwitchComponent } from '@app/components/form/switch/switch.component'
import { SearchService } from '@core/data-services/shared/search.service'
import { SearchResult } from '@core/models/shared/search.model'
import { ButtonComponent } from '@app/components/uiux/button/button.component'
import { ToggleButtonModule } from 'primeng/togglebutton'

@Component({
  selector: 'tb-search',
  standalone: true,
  templateUrl: 'searchbar.component.html',
  styleUrl: './searchbar.component.scss',
  encapsulation: ViewEncapsulation.None,
  imports: [
    InputTextModule,
    PaginatorModule,
    InputGroupModule,
    InputGroupAddonModule,
    ButtonModule,
    InputSwitchModule,
    TooltipModule,
    SearchOverlayComponent,
    NgIf,
    SwitchComponent,
    ButtonComponent,
    ToggleButtonModule
  ]
})
export class SearchbarComponent {

  protected inputValue: string = ''
  protected inputChanged: Subject<string> = new Subject<string>()
  protected isGlobalSearch: boolean = true
  protected dropdownVisible: boolean = false
  protected searchResults: SearchResult[] = []

  protected activeFilters: string[] = []

  constructor(
    protected localSearchService: LocalSearchService,
    private eRef: ElementRef,
    private searchService: SearchService) {

    this.localSearchService.subject$.subscribe(val => this.inputValue = val)
  }

  protected doSearch(): void {
    if(!this.isGlobalSearch) this.localSearchService.search(this.inputValue.trim())
    else this.doGlobalSearch()
  }

  protected doGlobalSearch(): void {
    this.searchService.search({
      "indices": this.activeFilters,
      "searchTerm": this.inputValue
    }).subscribe(searchResults => {
      this.searchResults = searchResults
    })
  }

  protected activeFilterChanged(filter: string): void {
    const index = this.activeFilters.indexOf(filter)
    if (index === -1) {
      this.activeFilters.push(filter)
    } else {
      this.activeFilters.splice(index, 1)
    }
  }

  protected onRouteChange(): void {
    this.dropdownVisible = false
  }

  onFocus(): void {
    this.dropdownVisible = true
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.dropdownVisible = false
    }
  }

}
